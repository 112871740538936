import { graphql } from 'gatsby';
import moment from 'moment';
import React, { useState } from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import FullResourceGrid from '../components/organisms/FullResourceGrid';
import ResourceListHero from '../components/organisms/ResourceListHero';
import ResourcesHeader from '../components/organisms/ResourcesHeader';
import SearchResults from '../components/organisms/SearchResults';
import { useSearch } from '../hooks/use-search';

const EventsPage = ({ data: { data, allWpEvent, siteSearchIndex } }) => {
  const [query, setQuery] = useState('');
  const results = useSearch(siteSearchIndex.index, query);

  const resourceResults = results
    .filter(({ nodeType }) => nodeType === 'Event')
    .map(
      ({ slug }) =>
        allWpEvent.edges.find((post) => post.node.slug === slug)?.node,
    );

  const [filter, setFilter] = useState<'past' | 'upcoming'>('past');

  const upcomingEvents = allWpEvent.edges
    .map(({ node }) => node)
    .filter(
      (node) =>
        node.event.eventDate !== null &&
        moment(node.event.eventDate) > moment(),
    );
  const pastEvents = allWpEvent.edges
    .map(({ node }) => node)
    .filter(
      (node) =>
        node.event.eventDate === null ||
        moment(node.event.eventDate) <= moment(),
    );

  const shownEvents = filter === 'upcoming' ? upcomingEvents : pastEvents;
  const showResourceGrid =
    filter === 'upcoming' ? shownEvents.length > 1 : shownEvents.length > 0;

  return (
    <Layout>
      <Seo post={data} />

      <ResourcesHeader activeSearchQuery={query} onQuery={setQuery} />
      {query.trim().length > 0 ? (
        <SearchResults resources={resourceResults} />
      ) : (
        <>
          <ResourceListHero {...shownEvents[0]} listTitle="Events" />
          <div className="border-b border-keyline" />
          {upcomingEvents.length > 1 && (
            <>
              <FullResourceGrid
                title="Upcoming Events"
                resources={upcomingEvents.slice(1)}
              />
              <div className="border-b border-keyline" />
            </>
          )}
          <FullResourceGrid title="Past Events" resources={pastEvents} />
        </>
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query EventsPageQuery {
    data: wpPage(slug: { eq: "events" }) {
      title
      ...SEO
    }
    allWpEvent(sort: { fields: dateGmt, order: DESC }) {
      edges {
        node {
          title
          nodeType
          slug
          excerpt
          dateGmt
          featuredImage {
            node {
              ...Image
            }
          }
          event {
            eventDate
          }
        }
      }
    }
    siteSearchIndex {
      index
    }
  }
`;

export default EventsPage;
