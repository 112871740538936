import { AnimatePresence, motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { IResource } from '../../interfaces/resource';
import ResourceTile from '../atoms/ResourceTile';

interface FullResourceGridProps {
  title: string;
  resources: Array<IResource>;
}

const FullResourceGrid: React.FC<FullResourceGridProps> = ({
  title,
  resources,
  children,
}) => {
  const {
    wp: {
      options: {
        globalOptions: { viewMoreLabel },
      },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        options {
          globalOptions {
            viewMoreLabel
          }
        }
      }
    }
  `);

  const [num, setNum] = useState(1);

  return (
    <section className="py-20 u-container lg:py-40">
      <div className="items-center justify-between space-y-4 lg:flex mb-13 lg:space-y-0">
        <h2 className="text-4xl lg:text-5.5xl leading-tighter tracking-pct font-semibold">
          {title}
        </h2>
        {children}
      </div>
      <div className="space-y-10">
        <AnimatePresence>
          {Array.from({ length: num }).map((_, blockIndex) => (
            <motion.div
              key={blockIndex}
              className="space-y-10"
              variants={{ off: { opacity: 0 }, on: { opacity: 1 } }}
              initial="off"
              animate="on"
              exit="off"
            >
              <div className="space-x-0 space-y-8 lg:flex lg:space-x-8 lg:space-y-0">
                {[...resources]
                  .slice(5 * blockIndex, 2 + 5 * blockIndex)
                  .map((resource, index) => (
                    <div className="lg:w-1/2" key={index}>
                      <ResourceTile {...resource} />
                    </div>
                  ))}
              </div>
              <div className="space-y-8 lg:flex lg:space-x-8 lg:space-y-0">
                {[...resources]
                  .slice(5 * blockIndex + 2, 5 * blockIndex + 5)
                  .map((resource, index) => (
                    <div className="lg:w-4/12" key={index}>
                      <ResourceTile {...resource} />
                    </div>
                  ))}
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      {num * 5 < resources.length && (
        <div className="text-center mt-18">
          <button
            className="u-btn u-btn--primary"
            onClick={() => setNum(num + 1)}
          >
            {viewMoreLabel}
          </button>
        </div>
      )}
    </section>
  );
};

export default FullResourceGrid;
